const english = {
  //HomeHeader
  ['The ACLU of Pennsylvania is the state’s oldest and largest civil rights organization. We are a non-partisan organization funded exclusively by the generous donations of our supporters. Our mission is to protect, defend and extend the civil rights and civil liberties of all people in Pennsylvania.']:
    'The ACLU of Pennsylvania is the state’s oldest and largest civil rights organization. We are a non-partisan organization funded by the generous donations of our supporters. Our mission is to protect, defend and extend the civil rights and civil liberties of all people in Pennsylvania.',
  ['The ACLU of Oregon is a non-profit']: 'The ACLU of Oregon is a non-profit, non-partisan organization. We fight to protect individual rights and fundamental freedoms guaranteed by the U.S. and Oregon Constitutions. First, please tell us if this instance happened in the state of Oregon, then you can choose your preferred language to file the complaint.',

  //Header File Form
  ['PLEASE BE ADVISED:']: 'PLEASE BE ADVISED:',
  ["The purpose of this intake form is for the ACLU of Pennsylvania ('ACLU-PA') to assess whether it can provide you with assistance or representation. Filling out this form does not create an attorney/client relationship between you and the ACLU-PA. The ACLU-PA does not represent you unless you and the ACLU-PA have both signed a written retainer agreement."]:
    "The purpose of this intake form is for the ACLU of Pennsylvania ('ACLU-PA') to assess whether it can provide you with assistance or representation. Filling out this form does not create an attorney/client relationship between you and the ACLU-PA. The ACLU-PA does not represent you unless you and the ACLU-PA have both signed a written retainer agreement.",
  ['You are solely responsible']: 'You are solely responsible',
  ['for any and all statutes of limitations or other deadlines applicable to your specific situation. To protect your rights, please consult with a private attorney to determine deadlines applicable to your case.']:
    'for any and all statutes of limitations or other deadlines applicable to your specific situation. To protect your rights, please consult with a private attorney to determine deadlines applicable to your case.',
  ['Do not wait to hear from the ACLU-PA before proceeding with your matter.']:
    'Do not wait to hear from the ACLU-PA before proceeding with your matter.',
  ['Please be sure to read']: 'Please be sure to read',
  ['before filling out this form.']: 'before filling out this form.',
  ['There may be deadlines that could affect your lawsuit or grievance. If you are concerned about whether the time for bringing your complaint is about to pass, you should not rely on filing an ACLU complaint to protect you; you should consult with an attorney of your choice.']:
    'There may be deadlines that could affect your lawsuit or grievance. If you are concerned about whether the time for bringing your complaint is about to pass, you should not rely on filing an ACLU complaint to protect you; you should consult with an attorney of your choice.',
  ['Pennsylvania Bar Lawyer Referral Service']:
    'Pennsylvania Bar Lawyer Referral Service',
  ['Pennsylvania Rio Grande Legal Aid']: 'Pennsylvania Rio Grande Legal Aid',
  ['Pennsylvania Access to Justice Commission']:
    'Pennsylvania Access to Justice Commission',
  ['Pennsylvania Law Help']: 'Pennsylvania Law Help',
  ['(click on “find a lawyer”) or call 1-877-9TEXBAR (toll free)']:
    '(click on “find a lawyer”) or call 1-877-9TEXBAR (toll free)',
  ['or call 1-888-988-9996 (toll free)']: 'or call 1-888-988-9996 (toll free)',
  ['(click on “find legal assistance”) or call 800-204-2222 ext. 2155']:
    '(click on “find legal assistance”) or call 800-204-2222 ext. 2155',
  ['If you would like to request legal assistance, fill out the form below.']:
    'If you would like to request legal assistance, fill out the form below.',

  // Header Form Complete
  ['WE HAVE RECEIVED YOUR COMPLAINT']: 'WE HAVE RECEIVED YOUR COMPLAINT',
  ['Unfortunately, we have very limited resources and we are unable to take most cases. If we are able to help you, we will be back in touch as soon as possible. You should continue to seek other counsel and be mindful that you have a limited period of time to file a lawsuit. Thank you for contacting us, and thank you for your patience.']:
    'Unfortunately, we have very limited resources and we are unable to take most cases. If we are able to help you, we will be back in touch as soon as possible. You should continue to seek other counsel and be mindful that you have a limited period of time to file a lawsuit. Thank you for contacting us, and thank you for your patience.',
  ['Return to ACLU Pennsylvania']: 'Return to ACLU Pennsylvania',

  //Misc
  ['Language']: 'Language',
  ['Submit Request']: 'Submit Request',
  ['Visit ACLU affiliates']: 'Visit ACLU affiliates',
  ['Indicates the required fields.']: 'Indicates the required fields.',
  ['This field is required.']: 'This field is required.',
  ['Please review the highlighted fields.']:
    'Please review the highlighted fields.',
  ['Select']: 'Select',
  ['PERMISSION TO COLLECT AND USE INFORMATION']: 'PERMISSION TO COLLECT AND USE INFORMATION',

  // Titles
  ['ACLU Oregon Online Complaint Form']:
    'Welcome To The ACLU of Oregon Complaint Form',
  ['ACLU OREGON ONLINE COMPLAINT FORM']:
    'ACLU OREGON ONLINE COMPLAINT FORM',
  ['THE LOCATION OF THIS INCIDENT']: 'THE LOCATION OF THIS INCIDENT',
  ['ONLINE COMPLAINT FORM']: 'ONLINE COMPLAINT FORM',
  ['CONTACT INFORMATION']: 'CONTACT INFORMATION',
  ['ONLINE FORM TO REQUEST LEGAL ASSISTANCE']:
    'ONLINE FORM TO REQUEST LEGAL ASSISTANCE',
  ['MY COMPLAINT IS AGAINST THE FOLLOWING (OPTIONAL)']:
    'MY COMPLAINT IS AGAINST THE FOLLOWING (OPTIONAL)',
  ['LAWSUIT INFORMATION (OPTIONAL)']: 'LAWSUIT INFORMATION (OPTIONAL)',
  ['DETAILS OF COMPLAINT']: 'DETAILS OF COMPLAINT',
  ['DISCLAIMER AND NOTICE']: 'DISCLAIMER AND NOTICE',
  ['ATTORNEY INFORMATION (OPTIONAL)']: 'ATTORNEY INFORMATION (OPTIONAL)',
  ['AGREEMENT']: 'AGREEMENT',

  // Incident Location
  ['Did this incident happen in Pennsylvania?']:
    'Did this incident happen in Pennsylvania?',
  ['Yes']: 'Yes',

  // Contact and Filer Information
  ['Are you contacting us for yourself or on behalf of someone else?']:
    'Are you contacting us for yourself or on behalf of someone else?',
  ['Are you filing for yourself or on behalf of someone else?']:
    'Are you filing for yourself or on behalf of someone else?',
  ['Myself']: 'Myself',
  ['Someone else']: 'Someone else',
  ['The relationship between you and the affected individual']:
    'The relationship between you and the affected individual',
  ['Contact information of the person affected by the incident']:
    'Contact information of the person affected by the incident',
  ['First Name']: 'First Name',
  ["Affected Individual's First Name"]: "Affected Individual's First Name",
  ["Affected Individual's Last Name"]: "Affected Individual's Last Name",
  ['Last Name']: 'Last Name',
  ['Gender Pronoun']: 'Gender Pronoun',
  ['Address line']: 'Address',
  ['Address line 2']: 'Address Line 2',
  ['State']: 'State',
  ['City']: 'City',
  ['Zip Code']: 'Zip Code',
  ['Phone Number']: 'Phone Number',
  ['Email']: 'Email',
  ['Title']: 'Title',
  ['If a prisoner, prison name?']: 'If a detained person, prison name?',
  ['Prisoner Number']: 'Prisoner Number',
  ['Contact Information of the person who is filing on behalf of the affected individual']:
    'Contact Information of the person who is filing on behalf of the affected individual',
  ['Ethnic Racial Background']: 'Ethnic/Racial Background (optional)',
  ['If we call you back']: "If we call you back, should we say that it's the ACLU calling?",

  // Complaint Against
  ['Agency']: 'Agency',
  ['May we contact this person or agency?']:
    'May we contact this person or agency?',

  // ATTORNEY INFORMATION (OPTIONAL)
  ['Are you represented by a lawyer in this matter?']:
    'Are you represented by a lawyer in this matter?',

  // Reported
  ['Have you reported this incident to any other agency?']:
    'Have you reported this incident to any other agency?',
  ['If yes, please list all the agency names.']:
    'If yes, please list all the agency names.',

  // Incident Information
  ['Date of Incident']: 'Date of Incident',
  ['County of Occurrence']: 'County of Occurrence',
  ['Please describe IN DETAIL, all the events that led you to file this complaint.']:
    'Please describe IN DETAIL, all the events that led you to file this complaint.',
  ['State clearly what you would like the ACLU of Pennsylvania to do for you.']:
    'State clearly what you would like the ACLU of Pennsylvania to do for you.',

  // Field Errors
  ['Please enter a valid email address.']:
    'Please enter a valid email address.',
  ['Please enter a valid phone number.']: 'Please enter a valid phone number.',
  ['Please enter a valid zip code.']: 'Please enter a valid zip code.',

  // Notices
  ['by_checking_this_box']:
    'By checking this box, I agree to allow ACLU Pennsylvania to collect and use the information I am providing to evaluate my request for legal assistance.',
  ['location_notice']:
    'Please tell us more information about which state this incident occurred. If the incident happened in another state, we will direct you to a page where you can obtain the contact information for the ACLU affiliate in the state in which the incident occurred. The Oregon ACLU only accepts cases that originate in Oregon.',
  ['affiliate_notice']:
    'If this incident did not arise in the state of Oregon, we cannot help you at this time. Please click the button below to redirect you to the correct ACLU affiliate.',
  ['contact_info_notice']:
    'Contact Information of the person affected by the incident',
  ['background_notice']:
    'The following Ethnic/Racial Background information is optional. This is used for internal purposes only to provide the ACLU of Pennsylvania demographic information about the communities it serves throughout Pennsylvania.',
  ['complaint_against_notice']:
    'Please enter information about the individual, agency, and/or organization your complaint is against.',
  ['filer_contact_info_notice']:
    'Contact Information of the person who is filing on behalf of the affected individual',
  ['incident_notice']:
    'Briefly describe the incident that led you to file a complaint with our office. Please include dates, places, and names of those directly involved. Note in your summary whether you have supporting documentation and we will reach out to you separately to obtain copies. If you have a link to any relevant video, please include the URL in the summary.',
  ['witness_notice']:
    'If you have a witness to support your claims, please enter their information here.',
  ['attorney_notice']:
    'Per Oregon Rules of Professional Conduct 4.2, the ACLU of Oregon legal team is unable to speak with people already represented by counsel without express permission from your attorney to do so. Please have your attorney reach out.',
  ['lawsuit_notice']:
    'If a criminal or civil lawsuit has been filed against you or on your behalf regarding your complaint, please provide the information here.',
  ['disclaimer_notice1']:
    'By submitting this form you agree that all of the information provided is true and correct to the best of your knowledge. Submitting this online complaint form is not an agreement by the ACLU of Oregon to provide you with legal representation. We have a small team that focuses our work on “impact litigation,” or cases that have the opportunity for broad relief and/or legal change. We also do not represent people in criminal matters. Our team will follow up if we have interest in your case and/or need more information to evaluate it. Unless or until you enter into a formal representation agreement with the ACLU of Oregon, it is important that you continue looking for an attorney and are aware of all deadlines that may apply to your case. For example, the Oregon Tort Claims Act allows you to sue public bodies for certain types of violations, and requires you to notify the public body within 180 days of your injury/incident that you intend to sue them. Missing legal deadlines may bar you from being able to have your day in court.',
  ['disclaimer_notice2']:
    'This Request is not a solicitation or an offer by the American Civil Liberties Union and its affiliates to represent you. We cannot promise you that the information you provide will lead to any specific action by the American Civil Liberties Union its affiliates. Once you complete the Request, the American Civil Liberties Union of Pennsylvania may not do anything—including contact you—about your situation.',
  ['disclaimer_notice3']:
    'If you fill out this Request, you agree that the American Civil Liberties Union, the national ACLU or its affiliates, if identified as part of the Request, or one of its coalition partners may use the information you give us, as long as we don’t include your name, address, email or phone number, for one or more of the following purposes: (1) legislative testimony, (2) litigation; (3) contacting a city, state or federal agency; or (4) telling your story to the public, including the media. If the American Civil Liberties Union, the national ACLU or its affiliates, or one of its coalition partners wants to identify you, we will contact you prior to doing so.',
  ['disclaimer_notice4']:
    'We will keep your name, address, telephone number and email confidential unless you give us permission to use it or unless we are ordered to turn it over by a court (although we will attempt to prevent any disclosure).',
  ['agreement_notice1']:
    'By clicking the "Submit Request" button, you indicate that the information you have provided is true and correct; that you understand that, by accepting this complaint form, the ACLU of Pennsylvania is not agreeing to represent you; and that you understand that the ACLU of Pennsylvania is not responsible for ensuring that any statute of limitations requirement or other deadline is met in your case.',
  ['agreement_notice2']:
    'If you submit a complaint, you agree that the ACLU of Pennsylvania or the national ACLU may use the information you give us, as long as we don’t include your name, address, email, or phone number. We will keep your name, address, telephone number, and email confidential unless you give us permission to use it or unless we are ordered to turn it over by a court (although we will attempt to prevent any disclosure).',

// Checkbox options
  ['Asian']:
    'Asian',
  ['Black']:
    'Black',
  ['Latino or Latina']:
    'Latino or Latina',
  ['Middle Eastern']:
    'Middle Eastern',
  ['Native American']:
    'Native American',
  ['Pacific Islander']:
    'Pacific Islander',
  ['White']:
    'White',
  ['Other']:
    'Other'
}

export default english
